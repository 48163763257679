import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FileUploader } from "@fawzisf/nidea-antd-admin-components";
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Api } from "../../api";
import DateArrayField from "../DateArrayField";
import { FieldErrorMessage } from "../FieldErrorMessage";
import AddRegistrantModal from "./AddRegistrantModal";

const CourseScheduleForm = ({ data, onChange, value, loading, currentDate }: any) => {
  const { handleSubmit, errors, control, reset, setError, clearErrors } = useForm({
    defaultValues: {
      dates: currentDate
        ? [
            {
              from: currentDate.startOf("day"),
              to: currentDate.endOf("day"),
            },
          ]
        : [],
      title: "",
      ...data,
    },
  });
  const onSuccess = () => {
    console.log("done");
  };

  const onSubmit = (data: any) => {
    if (!data.dates || data.dates.length === 0) {
      return message.error("This Slot needs a date");
    }
    onChange({ ...data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Space size="middle" style={{ width: "100%" }} direction="vertical">
        <Row gutter={6}>
          <Col span={24}>
            <label htmlFor={"title"}>Display Title (optional)</label>
            <div>
              <Controller
                control={control}
                as={<Input id="title" />}
                rules={{ required: false }}
                name="title"
              />
            </div>
            <FieldErrorMessage errors={errors} name="title" />
          </Col>
          <Col span={12}>
            <label htmlFor={"capacity"}>Capacity</label>
            <div>
              <Controller
                control={control}
                as={<InputNumber style={{ width: "100%" }} min={1} id="capacity" />}
                rules={{ required: true }}
                name="capacity"
              />
            </div>
            <FieldErrorMessage errors={errors} name="capacity" />
          </Col>
          <Col span={24}>
            <label htmlFor={"dates"}>Date</label>
            <div>
              <DateArrayField
                control={control}
                errors={errors}
                fieldName="dates"
                setError={setError}
                clearErrors={clearErrors}
              />
            </div>
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Space>
        <Button loading={loading} htmlType="submit" role={"submit"}>
          Submit
        </Button>
      </Space>
    </form>
  );
};
const CourseScheduleModal = ({
  button,
  data,
  currentDate,
  onChange,
  value,
  courseId,
  title = "Add Course Slot",
}: any) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    setVisible(!visible);
  };
  const onDelete = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this course schedule ? ",
      icon: <ExclamationCircleOutlined />,
      content: "this cannot be undone?",
      okText: "Yes",
      cancelText: "NO",
      onOk: () => {
        return new Promise((resolve, reject) => {
          Api.courses
            .deleteSlot({ id: courseId, data: { _id: data._id } })
            .then((newData) => {
              onChange && onChange(newData.data);
              resolve(newData);
            })
            .catch(reject)
            .finally(() => {
              setVisible(false);
            });
        });
      },
    });
  };
  const handleChange = async (value: any) => {
    setLoading(true);
    if (!data?._id) {
      const newData = await Api.courses.addSlot({ id: courseId, data: value });
      onChange && onChange(newData.data);
      setVisible(false);
    } else {
      const newData = await Api.courses.updateSlot({
        id: courseId,
        data: { _id: data._id, ...value },
      });
      onChange && onChange(newData.data);
      setVisible(false);
    }
    setLoading(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          justifyItems: "center",
        }}
      >
        {button(onClick)}
      </div>
      <Modal
        width="65%"
        bodyStyle={{ paddingTop: 0 }}
        footer={
          <>
            {data && data._id && (
              <Button onClick={onDelete} danger>
                Delete
              </Button>
            )}
          </>
        }
        onCancel={onClick}
        okText={null}
        destroyOnClose={true}
        title={title}
        visible={visible}
        cancelText={"Delete"}
        // onOk={this.handleOk}
        // confirmLoading={confirmLoading}
      >
        <Tabs defaultActiveKey="form">
          <Tabs.TabPane tab="Form" key="form">
            <CourseScheduleForm
              currentDate={currentDate}
              loading={loading}
              value={value}
              data={data}
              onChange={handleChange}
            />
          </Tabs.TabPane>
          {data && data._id && (
            <Tabs.TabPane tab="Registrants" key="registrants">
              {/* {JSON.stringify(data.registrants)} */}
              <div style={{ marginBottom: 10 }}>
                <AddRegistrantModal
                  onChange={(userId) => {
                    return new Promise(async (resolve, reject) => {
                      const res = await Api.courses.addRegistrantToSlot(courseId, data._id, userId);
                      onChange(res.data);
                      resolve(true);
                    });
                  }}
                  exclude={data.registrants?.map((reg: any) => reg.user._id)}
                />
              </div>
              <Table
                columns={[
                  {
                    title: "First Name",
                    dataIndex: "user",
                    key: "firstName",
                    render: (user) => <p>{user.firstName}</p>,
                  },
                  {
                    title: "Last Name",
                    dataIndex: "user",
                    key: "lastName",
                    render: (user) => <p>{user.lastName}</p>,
                  },
                  {
                    title: "Email",
                    dataIndex: "user",
                    key: "email",
                    render: (user) => <p>{user.email}</p>,
                  },
                  {
                    title: "Phone",
                    dataIndex: "user",
                    key: "phone",
                    render: (user) => <p>{user?.phones?.mobile}</p>,
                  },
                ]}
                dataSource={data.registrants}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Modal>
    </div>
  );
};

export default CourseScheduleModal;
