import React, { useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const initial = Array.from({ length: 10 }, (v, k) => k).map((k) => {
  const custom: any = {
    id: `id-${k}`,
    title: `Slider ${k}`,
  };

  return custom;
});

const grid = 8;
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result.map((i: any, index) => {
    i.index = index;
    return i;
  });

  return result;
};

function SingleItem({ item, index, renderItem, horizontal }: any) {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided: any) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div>{renderItem(item)}</div>
          {/* <div style={{
                        width: "200px",
                        border: "1px solid grey",
                        marginBottom: `${grid}px`,
                        backgroundColor: "lightblue",
                        padding: `${grid}px`,
                    }}>
                        {item.title}
                    </div> */}
        </div>
      )}
    </Draggable>
  );
}

const DataList = React.memo(function QuoteList({ items = [], renderItem, horizontal }: any) {
  return (
    <div
      style={{
        display: "flex",
        gap: 5,
        flexDirection: horizontal ? "row" : "column",
        flexWrap: "wrap",
      }}
    >
      {Array.isArray(items) &&
        items?.map((item: any, index: number) => (
          <SingleItem item={item} renderItem={renderItem} index={index} key={item.id} />
        ))}
    </div>
  );
});

const SortableList = ({ list, onChange, renderItem, horizontal }: any) => {
  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const list_clone = reorder(list, result.source.index, result.destination.index);

    onChange(list_clone);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable direction={horizontal ? "horizontal" : "vertical"} droppableId="list">
        {(provided: any) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <DataList renderItem={renderItem} items={list} horizontal={horizontal} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableList;
