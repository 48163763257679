import { Button, message, Result, Space, Row, Col, Input } from "antd";
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../../api";
import { useSubmitForm } from "../../helpers/forms";
import { useLoadFormData } from "../../hooks/useLoadFormData";
import { FieldErrorMessage } from "../FieldErrorMessage";
import InternalizationField from "../global/internalizationField";


const TagForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset } = useForm();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/tags')
    }
    // const imageFields = [
    //     {
    //         name: "image",
    //         singleValue: true,
    //     }
    // ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.tags.single, id, setValue: reset,
        //  imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.tags.create,
            updateApiCall: Api.tags.update,
            onSuccess,
            //  imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/tags">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <div className="white-container">
                    <Row style={{ width: "100%" }} gutter={12}>
                        <Col span={6}>
                            <label htmlFor={"name"}>Name</label>
                            <Controller
                                control={control}
                                as={<Input id={"name"} />}
                                rules={{ required: true }}
                                name="name"
                            />
                            <FieldErrorMessage errors={errors} name="name" />
                        </Col>

                    </Row>
                </div>
                {/* <InternalizationField fields={[{
                    title: "Name",
                    name: "name",
                    type: "text"
                }]} control={control} errors={errors} /> */}
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default TagForm;