import React from "react";
import { Link } from "react-router-dom";
import { Table } from "../Table";
import { Api } from "../../api";
import { Space, Image } from "antd";
import BasicTableFilter from "../BasicTableFilter";
import { Helmet } from "react-helmet";
import { ImagesHelpers } from "../../helpers/images";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    sorter: true,
    width: "20%",
  },
  {
    title: "Thumbnail",
    dataIndex: "thumbnailImage",
    key: "thumbnailImage",
    sorter: true,
    width: "10%",
    render: (value: string) => {
      return (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          <Image width={100} src={ImagesHelpers.formatUrl(value)} />
        </div>
      );
    },
  },
];

const CourseTable = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Helmet>
        <title>Courses Table | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Table
        tableId={"coursesTable"}
        deleteApiCall={Api.courses.delete}
        allowDelete={true}
        restoreApiCall={Api.courses.restore}
        actions={(record: any) => <Link to={`/courses/update/${record._id}`}>View</Link>}
        Filter={BasicTableFilter}
        apiCall={Api.courses.table}
        columns={columns}
        filterDefinitions={[
          {
            key: "_id",
            type: "number",
            label: "ID",
            span: 3,
          },
          {
            key: "title",
            type: "search",
            label: "Title",
          },
        ]}
      />
    </Space>
  );
};

export default CourseTable;
