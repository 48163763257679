import { Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Api } from '../../api';
import { Table } from '../Table';
import BasicTableFilter from '../BasicTableFilter';




const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: "name",
        sorter: true,
        width: '20%',
    },
    {
        title: 'Parent',
        dataIndex: 'parent',
        key: 'parent',
        render: (parent: any) => {
            return (<Link to={`/categories/update/${parent?._id}`}>{parent?.name}</Link>)
        },
        sorter: true,
        width: '20%',
    },
];

const CategoriesTable = () => {
    return (
        <Space style={{ width: '100%' }} direction="vertical">
            {/* <CategoriesFilter /> */}
            <Table
                tableId="CategoriesTable"
                columns={columns}
                deleteApiCall={Api.categories.delete}
                allowDelete={true}
                restoreApiCall={Api.categories.restore}
                actions={(record: any) => (
                    <Link to={`/categories/update/${record._id}`}>View</Link>
                )}
                Filter={BasicTableFilter}
                apiCall={Api.categories.table} />
        </Space>
    )
};

export default CategoriesTable;