import { Tabs } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import EventForm from "./EventForm";
import EventRegistrantsList from "./EventRegistrantsList";

const EventSinglePage = () => {
  let { id } = useParams();
  return (
    <Tabs defaultActiveKey={"1"}>
      <Tabs.TabPane key={"1"} tab="Basic">
        <EventForm />
      </Tabs.TabPane>

      <Tabs.TabPane key={"2"} tab="Registrants">
        <EventRegistrantsList />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default EventSinglePage;
