import React from "react";
import { useFieldArray, Controller, Control, FieldErrors } from "react-hook-form";
import { Col, Row, Input, Button, Space, Card } from "antd";
import { FieldErrorMessage } from "./FieldErrorMessage";
import Icon, { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import TextEditorInput from "./TextEditorInput";
import FileUploadV2 from "./fileUploadV2/FileUploadV2";

type CourseContentFieldProps = {
  control: Control;
  fieldName: string;
  errors: FieldErrors<any>;
};
const CourseContentField = ({ control, fieldName, errors }: CourseContentFieldProps) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <Card bodyStyle={{ padding: 15 }} title="Course Content">
      <Space style={{ width: "100%" }} direction="vertical">
        {fields.map((item, index) => {
          return (
            <Row key={index} style={{ width: "100%" }} gutter={12}>
              <Col span={24}>
                <Input.Group size="default">
                  <Row gutter={[5, 10]}>
                    <Col span={12}>
                      <label>Icon</label>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        as={<FileUploadV2 singleValue />}
                        name={`${fieldName}[${index}].icon`}
                      />
                    </Col>
                    <Col span={24}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        as={<Input placeholder="Text" id={`${fieldName}[${index}].text`} />}
                        name={`${fieldName}[${index}].text`}
                      />
                    </Col>
                    <Col span={2}>
                      <Button
                        block
                        style={{ height: "100%" }}
                        onClick={() => {
                          remove(index);
                        }}
                        icon={<DeleteOutlined />}
                      ></Button>
                    </Col>
                  </Row>
                </Input.Group>
              </Col>
            </Row>
          );
        })}
        <Button
          block
          onClick={() => {
            append({});
          }}
          icon={<PlusOutlined />}
        ></Button>
        <FieldErrorMessage errors={errors} name={fieldName} />
      </Space>
    </Card>
  );
};

export default CourseContentField;
