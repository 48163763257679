import React, { useEffect, useCallback, useRef, useState } from "react";
import { Row, Col, Input, Button, Checkbox, Space, Select } from "antd";
import { Controller, useForm } from "react-hook-form";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { OrderStatus } from "../../types/order";
import { Api } from "../../api";

export type ItemRequestTableFilterProp = {
  value: any;
  onChange: (value: any) => void;
  filterDefinitions?: any;
  deletedFilter?: boolean;
};
const default_definitions = [
  {
    key: "_id",
    type: "number",
    label: "ID",
    span: 3,
  },
  {
    key: "status",
    type: "string",
  },
  {
    key: "user",
    type: "string",
  },
];
const OrdersTableFilter = ({
  value,
  onChange,
  filterDefinitions,
  deletedFilter = true,
}: ItemRequestTableFilterProp) => {
  const { handleSubmit, errors, control, reset, watch } = useForm({ defaultValues: value });
  const [definitions, setDefinitions] = useState(filterDefinitions || default_definitions);
  const [websiteUsers, setWebsiteUsers] = useState([]);

  useEffect(() => {
    Api.websiteUsers.list().then((res) => {
      setWebsiteUsers(res.data);
    });
  }, []);
  console.log(websiteUsers);
  useEffect(() => {
    if (deletedFilter)
      setDefinitions([
        ...definitions,
        {
          key: "showDeleted",
          type: "showDeleted",
          excludeFromGenerator: true,
        },
      ]);
  }, [deletedFilter]);
  const clearFilter = () => {
    //@ts-ignore
    reset({ showDeleted: false });
    onChange({
      filter: {},
      definitions,
    });
  };

  const handleChange = useCallback(
    (v: any) => {
      onChange({
        filter: v,
        definitions,
      });
    },
    [onChange]
  );
  // const call = useCallback(() => {
  //     reset(value);
  // }, [value, reset]);
  // useEffect(() => {
  //     call();
  // }, [value, call])
  const watchAllFields = watch("showDeleted");
  const watchStatus = watch("status");
  const watchUser = watch("user");
  useEffect(() => {
    if (watchAllFields !== undefined && watchStatus !== undefined && watchUser !== undefined) {
      handleSubmit(handleChange)();
    }
  }, [watchAllFields]);
  useEffect(() => {
    handleSubmit(handleChange)();
  }, [watchStatus]);
  useEffect(() => {
    handleSubmit(handleChange)();
  }, [watchUser]);
  return (
    <div className="white-container" style={{ marginBottom: 12 }}>
      <form onSubmit={handleSubmit(handleChange)}>
        <Space size="large" style={{ width: "100%" }} direction="vertical">
          <Row style={{ width: "100%" }} gutter={12}>
            <Col span={3}>
              <div className="">
                <label className="mr-10" htmlFor={"_id"}>
                  ID
                </label>
                <Controller control={control} as={<Input id={"_id"} />} name={"_id"} />
              </div>
              <FieldErrorMessage
                style={{ textAlign: "center" }}
                errors={errors}
                name={"_id"}
                formatName={"ID"}
              />
            </Col>
            <Col span={3}>
              <div className="">
                <label className="mr-10" htmlFor={"_id"}>
                  Status
                </label>
                <Controller
                  control={control}
                  as={
                    <Select
                      allowClear={true}
                      style={{ width: "100%" }}
                      options={[
                        {
                          label: "Awaiting Shipping",
                          value: OrderStatus.awaitingShipping,
                        },
                        {
                          label: "Shipped",
                          value: OrderStatus.shipped,
                        },
                        {
                          label: "Canceled",
                          value: OrderStatus.canceled,
                        },
                        {
                          label: "Awaiting Payment",
                          value: OrderStatus.awaitingPayment,
                        },
                        {
                          label: "Completed",
                          value: OrderStatus.completed,
                        },
                      ]}
                    />
                  }
                  name={"status"}
                />
              </div>
              <FieldErrorMessage
                style={{ textAlign: "center" }}
                errors={errors}
                name={"status"}
                formatName={"Status"}
              />
            </Col>

            <Col span={5}>
              <div className="">
                <label className="mr-10" htmlFor={"_id"}>
                  User
                </label>
                <Controller
                  control={control}
                  as={
                    <Select
                      allowClear={true}
                      style={{ width: "100%" }}
                      showSearch
                      optionFilterProp="label"
                      options={websiteUsers.map((i: any) => ({
                        label: `${i.firstName as string} ${i.lastName as string}`,
                        value: i._id,
                      }))}
                    />
                  }
                  name={"user"}
                />
              </div>
              <FieldErrorMessage
                style={{ textAlign: "center" }}
                errors={errors}
                name={"user"}
                formatName={"User"}
              />
            </Col>
          </Row>
          <Row justify="end">
            <Space size="small">
              <div className="">
                <label className="mr-10" htmlFor={"showDeleted"}>
                  Show Deleted
                </label>
                <Controller
                  onChange={([e]: any) => e.target.checked}
                  control={control}
                  valueName="checked"
                  as={<Checkbox />}
                  name="showDeleted"
                />
              </div>
              <Button htmlType="submit" size="small" type="dashed">
                Submit
              </Button>
              <Button size="small" onClick={clearFilter} danger>
                Reset
              </Button>
            </Space>
          </Row>
        </Space>
      </form>
    </div>
  );
};

export default OrdersTableFilter;
