import React, { useState } from "react";
import { Button, Modal, Space, Row, Col, Input, Checkbox, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Controller, useForm } from "react-hook-form";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { Api } from "../api";

export const ResetPasswordModal = ({
  userId,
  resetPasswordCall,
}: {
  userId: number;
  resetPasswordCall: any;
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, errors, control, reset, watch } = useForm();
  const showModal = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
  };
  const submit = (data: any) => {
    if (!loading) {
      setLoading(true);
      resetPasswordCall({ id: userId, args: { password: data.password } })
        .then((res) => {
          message.success("Password Reset");
          hideModal();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div>
      <Button size="small" type="danger" onClick={showModal}>
        Reset Password <LockOutlined />
      </Button>
      <Modal
        destroyOnClose
        closable={!loading}
        onCancel={hideModal}
        style={{ padding: 10 }}
        title="RESET PASSWORD"
        visible={visible}
        footer={null}
      >
        <form className="white-container" onSubmit={handleSubmit(submit)}>
          <Space size="middle" style={{ width: "100%" }} direction="vertical">
            <Row style={{ width: "100%" }} gutter={12}>
              <Col span={24}>
                <label htmlFor={"name"}>Password</label>
                <Controller
                  control={control}
                  as={<Input.Password id={"password"} />}
                  rules={{
                    required: " password is required",
                    validate: (value) => {
                      const strongRegex = new RegExp(
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})"
                      );
                      return (
                        strongRegex.test(value) ||
                        "Password must be at least 6 character, containing an uppercase, lowercase a number and a special character"
                      );
                    },
                  }}
                  name="password"
                />
                <FieldErrorMessage errors={errors} name="password" />
              </Col>
              <Col span={24}>
                <label htmlFor={"confirmPassword"}>Confirm Password</label>
                <Controller
                  control={control}
                  as={<Input.Password id={"confirmPassword"} />}
                  rules={{
                    validate: (value) => value === watch("password") || "Passwords don't match.",
                  }}
                  name="confirmPassword"
                />
                <FieldErrorMessage errors={errors} name="confirmPassword" />
              </Col>
              <Col style={{ marginTop: 20 }} span={24}>
                <Controller
                  onChange={([e]: any) => e.target.checked}
                  control={control}
                  valueName="logout"
                  as={<Checkbox>Logout all sessions</Checkbox>}
                  name="logout"
                />
                <FieldErrorMessage errors={errors} name="logout" />
              </Col>

              <Col style={{ marginTop: 20 }} span={24}>
                <Button disabled={loading} type="danger" block htmlType="submit">
                  RESET
                </Button>
              </Col>
            </Row>
          </Space>
        </form>
      </Modal>
    </div>
  );
};
