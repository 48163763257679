import { Button, Card, Col, Input, List, Row } from 'antd';
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';



const AssessmentQuestionField: React.FC<any> = ({ nestIndex, control, register }) => {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `sections[${nestIndex}].questions`
    });

    return (
        <div>
            {fields.map((item, k) => {
                return (
                    <List>
                        <List.Item >
                            <Row gutter={8} align="bottom" justify="center" style={{ width: "100%" }}>
                                <Col span={20}
                                >
                                    <label>Question Title:</label>
                                    <Controller
                                        as={<Input />}
                                        name={`sections[${nestIndex}].questions[${k}].title`}
                                        control={control}
                                        defaultValue={item.title} // make sure to set up defaultValue
                                    />


                                </Col>

                                <Col span={4}
                                >
                                    <Button  onClick={() => remove(k)}>
                                        Delete
                                    </Button>
                                </Col>

                            </Row>
                        </List.Item>
                    </List>
                );
            })}
            <Button onClick={() => {
                append({});
            }}>Add Question</Button>
        </div>
    )
};

export default AssessmentQuestionField;