import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api";
import CourseForm from "./CourseForm";
import CourseRegistrants from "./CourseRegistrants";
import CourseScheduleEditor from "./CourseScheduleEditor";

const CourseSinglePage = () => {
  let { id } = useParams();
  const [courseData, setCourseData]: any = useState(null);
  useEffect(() => {
    if (id) {
      Api.courses.single(id).then((res) => {
        setCourseData(res.data.data);
      });
    }
  }, []);
  return (
    <Tabs defaultActiveKey={"1"}>
      <Tabs.TabPane key={"1"} tab="Basic">
        <CourseForm />
      </Tabs.TabPane>
      {courseData?.type === "virtual" && (
        <Tabs.TabPane key={"2"} tab="Schedule And Classes">
          <CourseScheduleEditor />
        </Tabs.TabPane>
      )}
      {courseData?.type !== "virtual" && (
        <Tabs.TabPane key={"3"} tab="Course Registrants">
          <CourseRegistrants />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};

export default CourseSinglePage;
