import { Button, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Api } from "../../api";

const AddRegistrantModal: React.FC<{
  exclude: any[];
  onChange: (user: string) => Promise<any>;
}> = ({ exclude = [], onChange }) => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const [websiteUsers, setWebsiteUsers] = useState([]);

  const [selectedUser, setSelectedUser]: any = useState(undefined);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    if (!selectedUser)
      Modal.error({
        title: "Kindly pick a user",
      });
    else {
      setConfirmLoading(true);
      await onChange(selectedUser);
      setConfirmLoading(false);
      setVisible(false);
      setSelectedUser(undefined);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    Api.websiteUsers.list().then((res) => {
      setWebsiteUsers(res.data);
    });
  }, []);

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Add Registrants
      </Button>
      <Modal
        destroyOnClose
        title="Add Registrants"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Select
          placeholder="Search For Website Users"
          onChange={(v) => {
            setSelectedUser(v);
          }}
          value={selectedUser}
          style={{ width: "100%" }}
          options={websiteUsers
            .filter((i: any) => {
              return !exclude.includes(i._id);
            })
            .map((i: any) => ({
              label: `${i.firstName} ${i.lastName} ${i.email || ""} ${i.phones?.mobile || ""}`,
              value: i._id,
            }))}
          showSearch
          optionFilterProp="label"
        />
      </Modal>
    </>
  );
};

export default AddRegistrantModal;
