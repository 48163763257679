import React from "react";
import { useFieldArray, Controller, Control, FieldErrors } from "react-hook-form";
import { Col, Row, Input, Button, Space, Card } from "antd";
import { FieldErrorMessage } from "./FieldErrorMessage";
import Icon, { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import TextEditorInput from "./TextEditorInput";
import FileUploadV2 from "./fileUploadV2/FileUploadV2";
import VideoSectionField from "./VideoSectionField";

type OnlineCourseSectionsFieldProps = {
  control: Control;
  fieldName: string;
  errors: FieldErrors<any>;
};
const OnlineCourseSectionsField = ({
  control,
  fieldName,
  errors,
}: OnlineCourseSectionsFieldProps) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <Card bordered={false} bodyStyle={{ padding: 15 }} title="Course Video Sections">
      <Row gutter={[24, 24]} style={{ width: "100%" }}>
        {fields.map((item, index) => {
          return (
            <Col span={24}>
              <Card title={`Section ${index + 1}`} bordered>
                <Row key={index} style={{ width: "100%" }} gutter={12}>
                  <Col span={24}>
                    <Input.Group size="default">
                      <Row gutter={[5, 10]}>
                        <Col span={24}>
                          <Controller
                            control={control}
                            rules={{ required: true }}
                            as={
                              <Input
                                placeholder="Section Title"
                                id={`${fieldName}[${index}].title`}
                              />
                            }
                            name={`${fieldName}[${index}].title`}
                          />
                        </Col>
                        <Col span={24}>
                          <VideoSectionField
                            control={control}
                            errors={errors}
                            fieldName={`${fieldName}[${index}].videos`}
                          />
                        </Col>
                        <Col span={24}>
                          <Col span={12}>
                            <label htmlFor={"files"}>Files</label>
                            <Controller
                              control={control}
                              as={<FileUploadV2 />}
                              name={`${fieldName}[${index}].files`}
                            />
                            <FieldErrorMessage
                              errors={errors}
                              name={`${fieldName}[${index}].files`}
                            />
                          </Col>
                        </Col>

                        <Col span={12}>
                          <Button
                            block
                            style={{ height: "100%" }}
                            onClick={() => {
                              remove(index);
                            }}
                            icon={<DeleteOutlined />}
                          >
                            Remove Section
                          </Button>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })}
        <Col span={24}>
          <Button
            block
            onClick={() => {
              append({});
            }}
            icon={<PlusOutlined />}
          >
            Add Section
          </Button>
        </Col>
        <FieldErrorMessage errors={errors} name={fieldName} />
      </Row>
    </Card>
  );
};

export default OnlineCourseSectionsField;
