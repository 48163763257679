import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './../Table';
import { Api } from '../../api';
import { Space } from 'antd';
import BasicTableFilter from '../BasicTableFilter';
//import { CategoriesFilter } from './CategoriesFilter';




const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: "name",
        sorter: true,
        width: '20%',
    },
];

const TagTable = () => {
    return (
        <Space style={{ width: '100%' }} direction="vertical">
            {/* <CategoriesFilter /> */}
            <Table
                tableId="tagsTable"
                deleteApiCall={Api.tags.delete}
                allowDelete={true}
                restoreApiCall={Api.tags.restore}
                actions={(record: any) => (
                    <Link to={`/tags/update/${record._id}`}>View</Link>
                )}
                Filter={BasicTableFilter}
                apiCall={Api.tags.table} columns={columns} />
        </Space>
    )
};

export default TagTable;