import { Skeleton, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Api } from "../../api";
import { Helpers } from "../../helpers";

const WebsiteUserAssesments = () => {
  let { id } = useParams();

  const [data, setData] = useState([]);
  useEffect(() => {
    if (id) {
      Api.websiteUsers.single(id).then((res) => {
        setData(res.data.data.assessmentResults || []);
      });
    }
  }, [id]);

  return (
    <div>
      <Table
        columns={[
          {
            title: "Engineering Score",
            dataIndex: "engineeringScore",
            key: "engineeringScore",
            render: (score) => <p>{score}/10</p>,
          },
          {
            title: "Management Score",
            dataIndex: "managementScore",
            key: "managementScore",
            render: (score) => <p>{score}/10</p>,
          },
          {
            title: "Date Taken",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => <p>{createdAt ? Helpers.formatDateFull(createdAt) : "-"}</p>,
          },
        ]}
        dataSource={data}
      />
    </div>
  );
};

export default WebsiteUserAssesments;
