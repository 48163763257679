import { Skeleton, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Api } from "../../api";
import { Helpers } from "../../helpers";
import AddRegistrantModal from "./AddRegistrantModal";

const CourseRegistrants = () => {
  let { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData]: any = useState([]);

  useEffect(() => {
    Api.courses
      .singleWithRegistrants(id)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  console.log(data);

  return (
    <div>
      {loading ? (
        <Skeleton />
      ) : (
        <div>
          <div style={{ marginBottom: 10 }}>
            <AddRegistrantModal
              onChange={(userId) => {
                return new Promise(async (resolve, reject) => {
                  const res = await Api.courses.addRegistrantToCourse(data._id, userId);
                  setData(res.data.data);
                  resolve(true);
                });
              }}
              exclude={data.registrants.map((reg: any) => reg.user._id)}
            />
          </div>
          <Table
            columns={[
              {
                title: "First Name",
                dataIndex: "user",
                key: "firstName",
                render: (user) => <p>{user.firstName}</p>,
              },
              {
                title: "Last Name",
                dataIndex: "user",
                key: "lastName",
                render: (user) => <p>{user.lastName}</p>,
              },
              {
                title: "Registration Date",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (date: Date) => (date ? Helpers.formatDateFull(date) : "-"),
              },
              {
                title: "Email",
                dataIndex: "user",
                key: "email",
                render: (user) => <p>{user.email}</p>,
              },
              {
                title: "Phone",
                dataIndex: "user",
                key: "phone",
                render: (user) => <p>{user?.phones?.mobile}</p>,
              },
            ]}
            dataSource={data.registrants}
          />
        </div>
      )}
    </div>
  );
};

export default CourseRegistrants;
