import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Result,
  Row,
  Space,
  Switch,
} from "antd";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../../api";
import { useSubmitForm } from "../../helpers/forms";
import { ImagesHelpers } from "../../helpers/images";
import { useLoadFormData } from "../../hooks/useLoadFormData";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FileUploader } from "../FileUploader";
import { Helmet } from "react-helmet";
import FileUploadV2 from "../fileUploadV2/FileUploadV2";
import TextEditorInput from "../TextEditorInput";
import CategorySelector from "../categories/CategorySelector";
import { TagsInput } from "../tags/TagsInput";
import moment from "moment";
import TestimonialArrayField from "../pages/TestimonialArrayField";

const EventForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm();
  let history = useHistory();
  const onSuccess = () => {
    message.success("Saved");
    history.push("/events");
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.events.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.events.create,
    updateApiCall: Api.events.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="primary">
            <Link to="/events">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Helmet>
        <title>Create Event | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Space size="middle" style={{ width: "100%" }} direction="vertical">
        <Row style={{ width: "100%" }} gutter={12}>
          <Col span={6}>
            <label htmlFor={"name"}>Name</label>
            <Controller
              control={control}
              as={<Input id={"name"} />}
              rules={{ required: true }}
              name="name"
            />
            <FieldErrorMessage errors={errors} name="name" />
          </Col>
        </Row>
        <Row>
          <Col span={2}>
            <label htmlFor={"isVirtual"}>Is Virtual </label>
            <Controller
              control={control}
              render={({ onChange, value }) => (
                <Switch checked={value} onChange={onChange} style={{ display: "block" }} />
              )}
              defaultValue={false}
              valueName="checked"
              name="isVirtual"
            />
            <FieldErrorMessage errors={errors} name="isVirtual" />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <label htmlFor={"price"}>Price</label>
            <div>
              <Controller
                control={control}
                as={<InputNumber style={{ width: "100%" }} min={0} id={"price"} />}
                rules={{ required: false }}
                name="price"
              />
            </div>
            <FieldErrorMessage errors={errors} name="price" />
          </Col>
        </Row>
        <Row style={{ width: "100%" }} gutter={12}>
          <Col span={6}>
            <label htmlFor={"meetingLink"}>Meeting Link</label>
            <Controller
              control={control}
              as={<Input id={"meetingLink"} />}
              rules={{ required: true }}
              name="meetingLink"
            />
            <FieldErrorMessage errors={errors} name="meetingLink" />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={6}>
            <label htmlFor={"date.from"}>Date From </label>
            <div>
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <DatePicker
                    showTime
                    format="DD/MM/YYYY hh:mm"
                    onChange={(v) => {
                      onChange(v?.toDate());
                    }}
                    value={value ? moment(value) : null}
                    style={{ width: "100%" }}
                  />
                )}
                rules={{ required: true }}
                name="date.from"
              />
            </div>
            <FieldErrorMessage errors={errors} name="date.from" />
          </Col>
          <Col span={6}>
            <label htmlFor={"date.to"}>Date To </label>
            <div>
              <Controller
                render={({ onChange, value }) => (
                  <DatePicker
                    showTime
                    format="DD/MM/YYYY hh:mm"
                    onChange={(v) => {
                      onChange(v?.toDate());
                    }}
                    value={value ? moment(value) : null}
                    style={{ width: "100%" }}
                  />
                )}
                control={control}
                rules={{ required: true }}
                name="date.to"
              />
            </div>
            <FieldErrorMessage errors={errors} name="date.to" />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <label htmlFor={"categories"}>Categories</label>
            <Controller
              control={control}
              as={<CategorySelector multi />}
              rules={{ required: false }}
              name="categories"
            />
            <FieldErrorMessage errors={errors} name="categories" />
          </Col>
          <Col span={6}>
            <label htmlFor={"tags"}>Tags</label>
            <Controller
              control={control}
              as={<TagsInput />}
              rules={{ required: false }}
              name="tags"
            />
            <FieldErrorMessage errors={errors} name="tags" />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor={"thumbnailImage"}>Thumbnail Image</label>
            <Controller control={control} as={<FileUploader singleValue />} name="thumbnailImage" />
            <FieldErrorMessage errors={errors} name="thumbnailImage" />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <label htmlFor={"description"}>Description</label>
            <Controller control={control} as={<TextEditorInput />} name="description" />
            <FieldErrorMessage errors={errors} name="description" />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <TestimonialArrayField fieldName="testimonials" control={control} errors={errors} />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>
        Submit
      </Button>
    </form>
  );
};

export default EventForm;
