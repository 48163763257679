import { Tabs } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import WebsiteUserAssesments from "./WebsiteUserAssessments";
import WebsiteUsersForm from "./WebsiteUsersForm";

const WebsiteUserSinglePage = () => {
  return (
    <Tabs defaultActiveKey={"1"}>
      <Tabs.TabPane key={"1"} tab="Form">
        <WebsiteUsersForm />
      </Tabs.TabPane>
      <Tabs.TabPane key={"2"} tab="Assessments">
        <WebsiteUserAssesments />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default WebsiteUserSinglePage;
