import { Button, Card, Col, Modal, Row, Spin } from "antd";
import "./fileUploader.css";
import React, { useEffect, useRef, useState } from "react";
import { useUpload } from "react-use-upload";
import { DeleteOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { store } from "../../store";
import { ImagesHelpers } from "../../helpers/images";
import { Base_Url } from "../../api";
import SortableList from "../SortableList";

const FileUploadV2: React.FC<any> = ({ value, onChange, singleValue }) => {
  const [previewImage, setPreviewImage]: [any, any] = useState(null);
  const [previewVisible, setPreviewVisible]: [any, any] = useState(false);
  let [files, setFiles]: any = useState();
  let { loading, progress, error, done, response } = useUpload(files, {
    path: `${Base_Url}/file/upload`,
    name: "file",
    // fields: { extraData: 'hello!' },
    headers: {
      Authorization: `Bearer ${store.getState().token}`,
    },
  });

  let inputRef: any = useRef(null);
  const handlePreview = (file: any) => {
    setPreviewImage(file);
    setPreviewVisible(true);
  };
  const handleCancel = () => {
    setPreviewVisible(false);
  };
  useEffect(() => {
    if (done && response) {
      if (singleValue) onChange(response.response.path);
      else onChange([...(value || []), response.response.path]);
      setFiles(undefined);
    }
  }, [response, done]);

  const onRemove = (index: string) => {
    let temp = (value as Array<any>).filter((v, ind) => ind !== parseInt(index));
    onChange(temp);
  };

  // alert(value);
  return (
    <div>
      <div style={{ display: "flex" }}>
        {singleValue && value && value !== "" ? (
          <div style={{ marginRight: 5, position: "relative" }}>
            <img
              className="image-uploader-img"
              alt="pl"
              style={{ width: 100, height: 100 }}
              src={ImagesHelpers.formatUrl(value)}
            />
            <div
              className="image-overlay"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 100,
                height: 100,
                position: "absolute",
                left: 0,
                top: 0,
              }}
            >
              <EyeOutlined
                onClick={() => {
                  handlePreview(ImagesHelpers.formatUrl(value));
                }}
                style={{ color: "white", cursor: "pointer", marginRight: 10 }}
              />
              <DeleteOutlined
                onClick={() => {
                  onChange(null);
                }}
                style={{ color: "white", cursor: "pointer" }}
              />
            </div>
          </div>
        ) : (
          value && (
            <SortableList
              horizontal
              renderItem={({ value, id }: any, index: any) => (
                <div
                  key={value}
                  style={{ marginRight: 5, minWidth: 100, minHeight: 100, position: "relative" }}
                >
                  {/\.(gif|jpg|jpeg|tiff|png)$/i.test(value) ? (
                    <img
                      className="image-uploader-img"
                      alt="pl"
                      style={{ width: 100, height: 100 }}
                      src={ImagesHelpers.formatUrl(value)}
                    />
                  ) : (
                    <div
                      style={{ width: 100, height: 100, background: "gray", overflow: "hidden" }}
                    >
                      {value}
                    </div>
                  )}

                  <div
                    className="image-overlay"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 100,
                      height: 100,
                      position: "absolute",
                      left: 0,
                      top: 0,
                    }}
                  >
                    <EyeOutlined
                      onClick={() => {
                        handlePreview(ImagesHelpers.formatUrl(value));
                      }}
                      style={{ color: "white", cursor: "pointer", marginRight: 10 }}
                    />
                    <DeleteOutlined
                      onClick={() => {
                        onRemove(id);
                      }}
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              )}
              list={
                Array.isArray(value) &&
                value?.map((v: any, index: any) => ({ id: index + "", value: v }))
              }
              onChange={(v: any) => {
                onChange(v?.map((i: any) => i.value));
              }}
            />
          )
        )}
        {loading && (
          <Card
            style={{
              width: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 100,
              background: "rgba(0, 0, 0, 0.2)",
            }}
          >
            <Spin />
          </Card>
        )}
      </div>
      <input
        ref={inputRef}
        style={{ width: 0, height: 0, overflow: "hidden" }}
        type="file"
        accept=""
        onClick={(e: any) => (e.target.value = null)}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            setFiles(e.target.files);
          }
        }}
      />
      {!loading && !error && (
        <Button
          style={{ marginTop: 5 }}
          icon={<UploadOutlined />}
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          Pick File
        </Button>
      )}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default FileUploadV2;
