import React from "react";
import { Link } from "react-router-dom";
import { Table } from "../Table";
import { Api } from "../../api";
import BasicTableFilter from "../BasicTableFilter";
import { ResetPasswordModal } from "../ResetPasswordModal";
import { Helpers } from "../../helpers";

const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
    sorter: true,
    width: "20%",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    sorter: true,
    width: "20%",
  },
  {
    title: "Career Level",
    dataIndex: "careerLevel",
    sorter: true,
    width: "20%",
  },
  {
    title: "Major",
    dataIndex: "major",
    sorter: true,
    width: "20%",
  },
  {
    title: "Assessments",
    dataIndex: "assessmentResults",
    sorter: true,
    render: (assessmentResults: any) => assessmentResults.length,
    width: "20%",
  },
  {
    title: "Egroup Expiry",
    dataIndex: "premiumSubscription",
    key: "expiry",
    render: (subscription: any) =>
      subscription ? (
        <p title={Helpers.formatDateFull(subscription.expiry)}>
          {Helpers.formatDateFromNow(subscription.expiry)}
        </p>
      ) : null,
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "DOB",
    dataIndex: "dateOfBirth",
    key: "dateOfBirth",
    render: (date: Date) => (date ? Helpers.formatDate(date) : "-"),
  },
  {
    title: "Mobile",
    dataIndex: "phones",
    key: "phones.mobile",
    render: (phones: any) => (phones && phones.mobile ? phones.mobile : "-"),
  },
];
const filter_definition = [
  {
    key: "_id",
    type: "number",
    label: "ID",
    span: 4,
  },
  {
    key: "firstName",
    type: "search",
    label: "First Name",
  },
  {
    key: "lastName",
    type: "search",
    label: "Last Name",
  },
  {
    key: "egroupActive",
    type: "egroupActive",
    label: "Egroup Active",
  },
  {
    key: "hasAssessment",
    type: "hasAssessment",
    label: "Has Taken Assessment",
  },

];
const WebsiteUsersTable = () => {
  return (
    <Table
      tableId="websiteUsersTable"
      columns={columns}
      deleteApiCall={Api.websiteUsers.delete}
      allowDelete={true}
      restoreApiCall={Api.websiteUsers.restore}
      filterDefinitions={filter_definition}
      actions={(record: any) => (
        <>
          <Link to={`/website-user/update/${record._id}`}>View</Link>
          <ResetPasswordModal
            resetPasswordCall={Api.websiteUsers.resetPassword}
            userId={record._id}
          />
        </>
      )}
      Filter={BasicTableFilter}
      apiCall={Api.websiteUsers.table}
    />
  );
};

export default WebsiteUsersTable;
