import { FileUploader } from "@fawzisf/nidea-antd-admin-components";
import { Button, Col, Input, message, Result, Row, Select, Space } from "antd";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api, Base_Url } from "../../api";
import { useCategoriesList } from "../../api/useCategoriesList";
import { useSubmitForm } from "../../helpers/forms";
import { ImagesHelpers } from "../../helpers/images";
import { useLoadFormData } from "../../hooks/useLoadFormData";
import { store } from "../../store";
import { FieldErrorMessage } from "../FieldErrorMessage";
import InternalizationField from "../global/internalizationField";
import { Helmet } from "react-helmet";


const CategoriesForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset } = useForm();
    const [categoriesList, loadingCategories] = useCategoriesList();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/categories')
    }
    const [notFound, loading] = useLoadFormData({
        apiCall: Api.categories.single, id, setValue: reset,
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.categories.create,
            updateApiCall: Api.categories.update,
            onSuccess,
            updateId: id,
        });
    // const [loading, setLoading] = useState(false);







    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/categories">Back to list</Link>
            </Button>}
        />)


    return (
        <form onSubmit={handleSubmit(submit)}>
            <Helmet>
                <title>Create Category | {process.env.REACT_APP_PROJECT_NAME}</title>
            </Helmet>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <div className="white-container">
                    <Row style={{ width: "100%" }} gutter={12}>
                        <Col span={6}>
                            <label htmlFor={"name"}>Name</label>
                            <Controller
                                control={control}
                                as={<Input id={"name"} />}
                                rules={{ required: true }}
                                name="name"
                            />
                            <FieldErrorMessage errors={errors} name="name" />
                        </Col>

                    </Row>

                    <Row gutter={6}>
                        <Col span={4}>
                            <label htmlFor={"image"}>Image</label>
                            <Controller
                                control={control}
                                render={({ onChange, value }) =>
                                    <FileUploader
                                        onChange={onChange}
                                        value={value || null}
                                        singleValue
                                        linkFormatter={(id: any) => `https://outof50.s3.eu-central-1.amazonaws.com/${id}`}
                                        uploadData={{
                                            path: `${Base_Url}/file/upload`,
                                            token: store.getState().token as string
                                        }} />}
                                name="image"
                            />
                            <FieldErrorMessage errors={errors} name="image" />
                        </Col>
                    </Row>

                </div>
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default CategoriesForm;