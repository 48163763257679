import { Button, List, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api";
import { Helpers } from "../../helpers";
import AddRegistrantModal from "../courses/AddRegistrantModal";

const EventRegistrantsList = () => {
  let { id } = useParams();

  const [registrants, setRegistrants] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Api.events
      .getRegistrants(id)
      .then((res) => {
        setRegistrants(res.data.registrants);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="white-container">
      <div style={{ display: "flex", gap: 10 }}>
        <Button href={`mailto:${registrants.map((reg: any) => reg.user.email).join(",")}`}>
          Mail All Registrants
        </Button>
        <div style={{ marginBottom: 10 }}>
          <AddRegistrantModal
            onChange={(userId) => {
              return new Promise(async (resolve, reject) => {
                const res = await Api.events.addRegistrant(id, userId);
                setRegistrants(res.data.data?.registrants);
                resolve(true);
              });
            }}
            exclude={registrants.map((reg: any) => reg.user._id)}
          />
        </div>
      </div>
      <Table
        loading={loading}
        columns={[
          {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
          },
          {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
          },
          {
            title: "Registration Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: Date) => (date ? Helpers.formatDateFull(date) : "-"),
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (email) => <a href={`mailto:${email}`}>{email}</a>,
          },
          {
            title: "Phone",
            dataIndex: "phones",
            key: "phones",
            render: (phones) => <a href={`tel:${phones?.mobile}`}>{phones?.mobile}</a>,
          },
        ]}
        dataSource={registrants.map((reg: any) => reg.user)}
      />
    </div>
  );
};

export default EventRegistrantsList;
