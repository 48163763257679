import { Badge, Button, Calendar } from "antd";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { Api } from "../../api";
import { useLoadFormData } from "../../hooks/useLoadFormData";
import {
  EditOutlined,
  PlusCircleOutlined,
  PlusCircleTwoTone,
  PlusSquareFilled,
  TeamOutlined,
} from "@ant-design/icons";
import CourseScheduleModal from "./CourseScheduleModal";
import { useForm } from "react-hook-form";
import * as Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);
const CourseScheduleEditor = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm();
  const [selectedDate, setSelectedDate] = useState(moment());
  let history = useHistory();
  const [notFound, loading, data, setData] = useLoadFormData({
    apiCall: Api.courses.single,
    id,
    setValue: reset,
    //  imageFields
  });

  function onPanelChange(value: any, mode: any) {
    console.log(value.format("YYYY-MM-DD"), mode);
  }
  function dateCellRender(value: moment.Moment) {
    let slots = [];
    if (data?.slots && data.slots[0]) {
      slots = data.slots.filter((slot: any) => {
        let range = moment.range(
          moment(slot.dates[0]?.from).startOf("day"),
          moment(slot.dates[0]?.to).endOf("day")
        );
        // console.log(value);
        return value.within(range);
      });
      // console.log(slots);
    }
    return (
      <>
        {value.startOf("day").isSame(selectedDate.startOf("day")) && (
          <CourseScheduleModal
            currentDate={selectedDate}
            courseId={id}
            data={{}}
            onChange={(data: any) => {
              setData({ ...data });
            }}
            button={(onClick: any) => (
              <Button
                style={{ marginRight: "auto" }}
                size="small"
                type="default"
                icon={<PlusCircleTwoTone />}
                onClick={onClick}
              >
                Add Course
              </Button>
            )}
          />
        )}
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
            margin: 0,
            padding: 0,
          }}
        >
          {slots.map((slot: any) => (
            <li key={slot._id}>
              <CourseScheduleModal
                title={`Edit Slot ${slot.title}`}
                onChange={(data: any) => {
                  setData({ ...data });
                }}
                courseId={id}
                data={{ ...slot }}
                button={(onClick: any) => (
                  <Button
                    style={{ marginRight: "auto" }}
                    size="small"
                    type="default"
                    // icon={<EditOutlined />}
                    onClick={onClick}
                  >
                    {slot.title || ""} <TeamOutlined /> {slot.capacity}
                  </Button>
                )}
              />
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <div>
      <Calendar
        onChange={(d: any) => {
          setSelectedDate(d);
          console.log(d);
        }}
        dateCellRender={dateCellRender}
        onPanelChange={onPanelChange}
      />
    </div>
  );
};

export default CourseScheduleEditor;
