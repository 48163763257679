import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, Space } from "antd";
import React from "react";
import { Control, Controller, FieldErrors, useFieldArray } from "react-hook-form";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";

type VideoSectionFieldProps = {
  control: Control;
  fieldName: string;
  errors: FieldErrors<any>;
};
const VideoSectionField = ({ control, fieldName, errors }: VideoSectionFieldProps) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <Card bodyStyle={{ padding: 15 }} title="Videos">
      <Space style={{ width: "100%" }} direction="vertical">
        {fields.map((item, index) => {
          return (
            <Row key={index} style={{ width: "100%" }} gutter={12}>
              <Col span={24}>
                <Input.Group size="default">
                  <Row gutter={[5, 10]}>
                    <Col span={12}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        as={<Input placeholder="Video Title" id={`${fieldName}[${index}].title`} />}
                        name={`${fieldName}[${index}].title`}
                      />
                    </Col>
                    <Col span={24}>
                      <label htmlFor={"video"}>Video</label>
                      <Controller
                        control={control}
                        as={<FileUploader limit={1} singleValue />}
                        rules={{ required: false }}
                        name={`${fieldName}[${index}].video`}
                      />
                      <FieldErrorMessage errors={errors} name={`${fieldName}[${index}].video`} />
                    </Col>

                    <Col span={12}>
                      <Button
                        block
                        style={{ height: "100%" }}
                        onClick={() => {
                          remove(index);
                        }}
                        icon={<DeleteOutlined />}
                      >
                        Remove Video
                      </Button>
                    </Col>
                  </Row>
                </Input.Group>
              </Col>
            </Row>
          );
        })}
        <Button
          block
          onClick={() => {
            append({});
          }}
          icon={<PlusOutlined />}
        ></Button>
        <FieldErrorMessage errors={errors} name={fieldName} />
      </Space>
    </Card>
  );
};

export default VideoSectionField;
