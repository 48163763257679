import { Button, Card, Divider, Input } from 'antd';
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { FieldErrorMessage } from '../FieldErrorMessage';
import AssessmentQuestionField from './AssessmentQuestionField';


const AssessmentSectionField: React.FC<any> = ({ control, setValue, getValues, errors }) => {
    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "sections"
    });

    return (
        <>
            <div>
                {fields.map((item, index) => {
                    return (
                        <Card title={item.title} extra={<button type="button" onClick={() => remove(index)}>
                            Delete
                        </button>} key={item.id}>
                            <label htmlFor={"title"}>Title</label>
                            <Controller
                                as={<Input />}
                                name={`sections[${index}].title`}
                                control={control}
                                defaultValue={item.title} // make sure to set up defaultValue
                            />
                            <FieldErrorMessage errors={errors} name={`sections[${index}].title`} />
                            <Divider>Questions</Divider>
                            <AssessmentQuestionField nestIndex={index} {...{ control }} />



                            {/* <NestedArray nestIndex={index} {...{ control, register }} /> */}
                        </Card>
                    );
                })}
            </div>
            <Button style={{ marginTop: 10 }} onClick={() => {
                append({});
            }}>Add Section</Button>
            <section>

            </section>
        </>
    )
};


export default AssessmentSectionField;