import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Base_Url } from '../api';
import { ImagesHelpers, IMAGE_ROOT } from '../helpers/images';
import { store } from '../store';


const getBase64: (file: any) => Promise<string> = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString());
        reader.onerror = error => reject(error);
    });
}




export const FileUploader: React.FC<{ value?: [UploadFile], onChange?: (value: UploadFile[]) => void, limit?: number, singleValue?: boolean }>
    = ({ value = null, onChange, limit = 1, singleValue }) => {
        // const [fileList, setFileList] = useState([]);
        const [previewImage, setPreviewImage]: [any, any] = useState(null);
        const [previewVisible, setPreviewVisible]: [any, any] = useState(false);
        const [localState, setLocalState]: any = useState(null);

        useEffect(() => {
            if (!localState && value) {
                if (singleValue)
                    setLocalState([{ name: value, uid: value, url: ImagesHelpers.formatUrl(value as any) }]);
                else
                    if (value && value.map)
                        setLocalState(value.map(v => ({
                            name: v,
                            uid: v,
                            url: ImagesHelpers.formatUrl(v as any)
                        })))
            }
        }, [value]);
        const handlePreview = async (file: UploadFile) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            setPreviewImage((file.url && IMAGE_ROOT + file.url.replace(IMAGE_ROOT, "")) || file.preview);
            setPreviewVisible(true);
        };
        const handleChange: ((args: any) => void) = ({ fileList }) => {

            // console.log(fileList);
            // onChange && onChange(singleValue ? fileList[0] : fileList);
            const doneFiles: any = [];
            fileList.map((f: UploadFile) => {
                if (f.response && f.response.path) {
                    f = f.response.path;
                    doneFiles.push(f);
                }
                else {
                    if (f.url)
                        doneFiles.push(f.uid);
                }
                return f;
            })
            if (doneFiles[0])
                onChange && onChange(singleValue ? doneFiles[0] : doneFiles);
            else
                onChange && onChange(null as any);
            setLocalState(fileList);
        }
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const handleCancel = () => {
            setPreviewVisible(false);
        }

        return (
            <div className="clearfix">
                <Upload
                    multiple={false}
                    maxCount={singleValue ? 1 : 5}
                    withCredentials={false}
                    headers={{
                        "Authorization": `Bearer ${store.getState().token}`
                    }}
                    action={`${Base_Url}/file/upload`}
                    // defaultFileList={singleValue ? value ? [{
                    //     uid: value,
                    //     status: "done",
                    //     name: value,
                    //     url: ImagesHelpers.formatUrl(value as any),
                    // }] : [] : value?.map((v: any) => (
                    //     {
                    //         uid: v,
                    //         status: "done",
                    //         name: v,
                    //         url: ImagesHelpers.formatUrl(v),
                    //     }
                    // ))}
                    fileList={localState}
                    onPreview={handlePreview}
                    listType="picture-card"
                    // fileList={value?.map(v => {
                    //     console.log(v);
                    //     return v;
                    // })}
                    onChange={handleChange}
                >
                    {uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    };