import React, { useEffect, useCallback, useRef, useState } from "react";
import { Row, Col, Input, Button, Checkbox, Space } from "antd";
import { Controller, useForm } from "react-hook-form";
import { FieldErrorMessage } from "./FieldErrorMessage";
import SelectWithApiData from "./SelectiWithApiData";

export type BasicTableFilterProp = {
  value: any;
  onChange: (value: any) => void;
  filterDefinitions?: any;
  deletedFilter?: boolean;
};
const default_definitions = [
  {
    key: "_id",
    type: "number",
    label: "ID",
    span: 3,
  },
  {
    key: "name",
    type: "search",
    label: "Name",
  },
];
const BasicTableFilter = ({
  value = {},
  onChange,
  filterDefinitions,
  deletedFilter = true,
}: BasicTableFilterProp) => {
  const { handleSubmit, errors, control, reset, watch } = useForm({ defaultValues: value });
  const [definitions, setDefinitions] = useState(filterDefinitions || default_definitions);

  useEffect(() => {
    if (deletedFilter)
      setDefinitions([
        ...definitions,
        {
          key: "showDeleted",
          type: "showDeleted",
          excludeFromGenerator: true,
        },
      ]);
  }, [deletedFilter]);
  const clearFilter = () => {
    reset({ showDeleted: false as any });
    onChange({
      filter: {},
      definitions,
    });
  };

  const handleChange = useCallback(
    (v: any) => {
      console.log(v);
      onChange({
        filter: v,
        definitions,
      });
    },
    [onChange]
  );

  const renderDefinition = (definition: any) => {
    switch (definition.type) {
      case "multiValue":
        return (
          <Col key={definition.key} span={definition.span || 6}>
            <div className="">
              <label className="mr-10" htmlFor={definition.key}>
                {definition.label}:{" "}
              </label>
              <Controller
                control={control}
                as={
                  <SelectWithApiData
                    apiCall={definition.apiCall}
                    dataMappingFunction={definition.dataMappingFunction}
                  />
                }
                name={definition.key}
              />
            </div>
            <FieldErrorMessage
              style={{ textAlign: "center" }}
              errors={errors}
              name={definition.key}
              formatName={definition.label}
            />
          </Col>
        );
      case "hasAssessment":
      case "boolean":
        return (
          <Col key={definition.key} style={{ marginTop: 15 }} span={definition.span || 6}>
            <div className="">
              <label className="mr-10" htmlFor={definition.key}>
                {definition.label}:{" "}
              </label>
              <Checkbox
                checked={value[definition.key]}
                onChange={(e) => {
                  onChange({
                    filter: { ...value, [definition.key]: e.target.checked },
                    definitions,
                  });
                }}
              />
            </div>
          </Col>
        );
      case "egroupActive":
        return (
          <Col key={definition.key} style={{ marginTop: 15 }} span={definition.span || 6}>
            <div className="">
              <label className="mr-10" htmlFor={definition.key}>
                {definition.label}:{" "}
              </label>
              <Checkbox
                checked={value[definition.key]}
                onChange={(e) => {
                  onChange({
                    filter: { ...value, [definition.key]: e.target.checked },
                    definitions,
                  });
                }}
              />
            </div>
          </Col>
        );
      default:
        return (
          <Col key={definition.key} span={definition.span || 6}>
            <div className="">
              <label className="mr-10" htmlFor={definition.key}>
                {definition.label}:{" "}
              </label>
              <Input
                value={value[definition.key]}
                onChange={(e) => {
                  onChange({ filter: { ...value, [definition.key]: e.target.value }, definitions });
                }}
                id={definition.key}
              />
            </div>
            <FieldErrorMessage
              style={{ textAlign: "center" }}
              errors={errors}
              name={definition.key}
              formatName={definition.label}
            />
          </Col>
        );
    }
  };

  return (
    <div className="white-container" style={{ marginBottom: 12 }}>
      <form onSubmit={handleSubmit(handleChange)}>
        <Space size="large" style={{ width: "100%" }} direction="vertical">
          <Row style={{ width: "100%" }} align="middle" gutter={12}>
            {definitions
              .filter((def: any) => !def.excludeFromGenerator)
              .map((def: any) => renderDefinition(def))}
          </Row>
          <Row justify="end">
            <Space size="small">
              <div className="">
                <label className="mr-10" htmlFor={"showDeleted"}>
                  Show Deleted
                </label>

                <Checkbox
                  checked={value.showDeleted}
                  onChange={(e) => {
                    onChange({ filter: { ...value, showDeleted: e.target.checked }, definitions });
                  }}
                />
              </div>
              <Button htmlType="submit" size="small" type="dashed">
                Submit
              </Button>
              <Button size="small" onClick={clearFilter} danger>
                Reset
              </Button>
            </Space>
          </Row>
        </Space>
      </form>
    </div>
  );
};

export default BasicTableFilter;
