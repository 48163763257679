import React from "react";
import { Link } from "react-router-dom";
import { Table } from "../Table";
import { Api } from "../../api";
import { Space } from "antd";
import BasicTableFilter from "../BasicTableFilter";
import { Helmet } from "react-helmet";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    sorter: true,
    width: "20%",
  },
];

const BlogTable = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Helmet>
        <title>Blogs Table | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Table
        tableId={"blogsTable"}
        deleteApiCall={Api.blogs.delete}
        allowDelete={true}
        restoreApiCall={Api.blogs.restore}
        actions={(record: any) => <Link to={`/blogs/update/${record._id}`}>View</Link>}
        Filter={BasicTableFilter}
        apiCall={Api.blogs.table}
        columns={columns}
        filterDefinitions={[
          {
            key: "_id",
            type: "number",
            label: "ID",
            span: 3,
          },
          {
            key: "title",
            type: "search",
            label: "Title",
          },
          {
            key: "eGroupExclusive",
            type: "boolean",
            label: "E-Groups Only",
          },
        ]}
      />
    </Space>
  );
};

export default BlogTable;
