import { Button, Col, Input, message, Result, Row, Space } from "antd";
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../../api";
import { useSubmitForm } from "../../helpers/forms";
import { ImagesHelpers } from "../../helpers/images";
import { useLoadFormData } from "../../hooks/useLoadFormData";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FileUploader } from "../FileUploader";
import { Helmet } from "react-helmet";
import AssessmentSectionField from "./AssesmentSectionField";

const AssessmentForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset, getValues, setValue } = useForm({
        defaultValues: {
            name: "",
            sections: []
        }
    });

    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/assessments')
    }


    const [notFound, loading] = useLoadFormData({
        apiCall: Api.assessments.single, id, setValue: reset,
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.assessments.create,
            updateApiCall: Api.assessments.update,
            onSuccess,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/assessments">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Helmet>
                <title>Create Assessment | {process.env.REACT_APP_PROJECT_NAME}</title>
            </Helmet>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"name"}>Name</label>
                        <Controller
                            control={control}
                            as={<Input id={"name"} />}
                            rules={{ required: true }}
                            name="name"
                        />
                        <FieldErrorMessage errors={errors} name="name" />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <AssessmentSectionField 

                            {...{ control, getValues, setValue, errors }}
                        />


                    </Col>
                </Row>
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default AssessmentForm;